import { authService } from './core/services';
import Login from './views/Login';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { logoutUser } from './utils';
import { commonAuthRoutes } from './routes';

const App = () => {

  const urlParams = new URLSearchParams(window.location.search);
  const redirect = urlParams.get('redirect');

  if (redirect && authService.isUserAuthenticated()) {
    window.location.href = redirect;
    return null;
  }

  if (redirect) {
    authService.refreshToken().then((refreshed) => {
      if (!refreshed) {
        logoutUser();
        return;
      }

      window.location.href = redirect;
    });
    return null;
  }

  const routesForNotAuthenticatedOnly = [
    {
      path: "/",
      element: <Login />,
    },
  ];

  const router = createBrowserRouter(authService.isUserAuthenticated() ? commonAuthRoutes : routesForNotAuthenticatedOnly);
  return <RouterProvider router={router} />;
}

export default App;