
export const roles = [{
  cognitoName: 'user001',
  name: 'User',
  entrypoint: '/user/',
}, {
  cognitoName: 'service000',
  name: 'Service',
  entrypoint: '/service/'
}, {
  cognitoName: 'admin000',
  name: 'Admin',
  entrypoint: '/admin/',
}, {
  cognitoName: 'all',
  name: 'Profile',
  entrypoint: '/profile/',
}]