import { userService } from "../core/services";
import { roles } from "../views/SelectRole/constants";

export const useRedirectByRole = () => {
  const idToken = localStorage.getItem('idToken');

  if(!idToken) {
    return { redirect: false };
  }

  const groups = userService.getGroups(idToken);
  if(!groups.length || groups.length > 1) {
    return { redirect: false };
  }
  
  const roleData = roles.find(({cognitoName}) => cognitoName === groups[0]);

  if(!roleData) {
    return { redirect: false };
  }

  window.location.href = roleData.entrypoint;
  return { redirect: true };
}