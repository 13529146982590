import BasicForm from "../../components/BasicForm";
import { FormInputContent } from "../../types";


type Inputs = {

}

const inputs: FormInputContent = {
  "controlDate": {
    "label": "Fecha del control",
    "type": "date"
  },
  "registrantId": {
    "label": "Identificador del registrante",
    "type": "number"
  },
  "opinionDietPattern": {
    "label": "Opinión de la pauta alimentaria",
  },
  "opinionTrainingPlan": {
    "label": "Opinión sobre el plan de entrenamiento",
  },
  "desiredPatternChanges": {
    "label": "Modificaciones deseadas de la pauta",
  },
  "perceptionChangesSinceLastControl": {
    "label": "Percepción de cambios desde el último control",
  },
  "evaluationChangesDietaryHabits": {
    "label": "Evaluación de cambios de hábitos alimentarios",
  },
  "satisfactionWithResults": {
    "label": "Satisfacción con los resultados",
  },
  "height": {
    "label": "Estatura",
    "type": "number",
    step: "0.01"
  },
  "weight": {
    "label": "Peso",
    "type": "number",
    step: "0.01"
  },
  "bodyMassIndex": {
    "label": "Índice de masa corporal",
    "type": "number",
    step: "0.01"
  },
  "bmiClassification": {
    "label": "Clasificación de índice de masa corporal",
    "type": "number"
  },
  "bodyFatPercentage": {
    "label": "Porcentaje de grasa corporal",
    "type": "number",
    step: "0.01"
  },
  "visceralFatPercentage": {
    "label": "Porcentaje de grasa visceral",
    "type": "number",
    step: "0.01"
  },
  "muscleMassPercentage": {
    "label": "Porcentaje de masa muscular",
    "type": "number",
    step: "0.01"
  },
  "idealWeight": {
    "label": "Peso ideal",
    "type": "number",
    step: "0.01"
  },
  "waistMeasurement": {
    "label": "Medida cintura",
    "type": "number",
    step: "0.01"
  },
  "hipMeasurement": {
    "label": "Medida cadera",
    "type": "number",
    step: "0.01"
  },
  "armMeasurement": {
    "label": "Medida brazos",
    "type": "number",
    step: "0.01"
  },
  "thighMeasurement": {
    "label": "Medida muslos",
    "type": "number",
    step: "0.01"
  },
  "basalMetabolicRate": {
    "label": "Tasa metabólica basal",
    "type": "number"
  },
  "targetCalories": {
    "label": "Calorías objetivo",
    "type": "number"
  },
  "targetCarbohydrates": {
    "label": "Carbohidratos objetivo",
    "type": "number",
    step: "0.01"
  },
  "targetProteins": {
    "label": "Proteínas objetivo",
    "type": "number",
    step: "0.01"
  },
  "targetFats": {
    "label": "Grasas objetivo",
    "type": "number",
    step: "0.01"
  },
  "abdominalPain": {
    "label": "Dolor abdominal",
    component: 'select',
    options: [{ label: 'Si', value: 'true' }, { label: 'No', value: 'false' }]
  },
  "stomachAcidity": {
    "label": "Acidez estomacal",
    component: 'select',
    options: [{ label: 'Si', value: 'true' }, { label: 'No', value: 'false' }]
  },
  "nausea": {
    "label": "Nauseas",
    component: 'select',
    options: [{ label: 'Si', value: 'true' }, { label: 'No', value: 'false' }]
  },
  "vomiting": {
    "label": "Vómitos",
    component: 'select',
    options: [{ label: 'Si', value: 'true' }, { label: 'No', value: 'false' }]
  },
  "diarrhea": {
    "label": "Diarrea",
    component: 'select',
    options: [{ label: 'Si', value: 'true' }, { label: 'No', value: 'false' }]
  },
  "constipation": {
    "label": "Estreñimiento",
    component: 'select',
    options: [{ label: 'Si', value: 'true' }, { label: 'No', value: 'false' }]
  },
  "abdominalDistension": {
    "label": "Distención abdominal",
    component: 'select',
    options: [{ label: 'Si', value: 'true' }, { label: 'No', value: 'false' }]
  },
  "flatulence": {
    "label": "Flatulencia",
    component: 'select',
    options: [{ label: 'Si', value: 'true' }, { label: 'No', value: 'false' }]
  },
  "gastrointestinalBleeding": {
    "label": "Sangrado gastrointestinal",
    component: 'select',
    options: [{ label: 'Si', value: 'true' }, { label: 'No', value: 'false' }]
  },
  "stoolPatternChanges": {
    "label": "Cambios de patrón de las heces",
    component: 'select',
    options: [{ label: 'Si', value: 'true' }, { label: 'No', value: 'false' }]
  },
  "comments": {
    "label": "Comentarios"
  }
}

const NutritionalControls = () => {

  return <BasicForm
    title={'Registro de controles nutricionales'}
    inputs={inputs}
    onSubmit={(data: Inputs) => console.log(data)}
    defaultValues={undefined}
    />;
};

export default NutritionalControls;