import { useRedirectByRole } from "../../hooks/useRedirectByRole";
import { logoutUser } from "../../utils";
import { roles } from "./constants";

const SelectRole = () => {
  const { redirect } = useRedirectByRole();

  if(redirect) {
    return null;
  }

  return <div>
    {roles.map(({ name, entrypoint }) => <div key={name} className="flex justify-center mb-5">
      <button
        className="flex justify-center rounded bg-primary py-2 px-6 font-medium text-gray hover:shadow-1"
        onClick={() => {
          window.location.href = entrypoint
        }}
      >
        {name}
      </button>
    </div>)}
    <div className="flex justify-center mb-5">
      <button
        className="flex justify-center rounded bg-primary py-2 px-6 font-medium text-gray hover:shadow-1"
        onClick={logoutUser}
      >
        Logout
      </button>
    </div>
  </div>
};

export default SelectRole;