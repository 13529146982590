import BasicForm from "../../components/BasicForm";
import { FormInputContent } from "../../types";

type Inputs = {
  name: string;
  middle_name: string;
  family_name: string;
  'custom:2doapellido': string;
  email: string;
  identifier_type: string;
  identifier: string;
  phone: string;
  country: string;
  birthdate: string;
  release: number;
  roles: Array<string>;
  tags: Array<string>;
}

const inputs: FormInputContent = {
  name: {
    label: 'Primer nombre',
  },
  middle_name: {
    label: 'Segundo nombre',
  },
  family_name: {
    label: 'Primer apellido'
  },
  'custom:2doapellido': {
    label: 'Segundo apellido'
  },
  email: {
    label: 'Email',
    type: 'email'
  },
  identifier_type: {
    label: 'Tipo de identificador',
    component: 'select',
    options: [{ label: 'RUT', value: 'rut' }, { label: 'Pasaporte', value: 'passport' }]
  },
  identifier: {
    label: 'Identificador'
  },
  phone: {
    label: 'Celular'
  },
  country: {
    label: 'País de residencia',
    component: 'select',
    options: [
      { label: 'Chile', value: 'CL' }, { label: 'Otro', value: 'XX' }
    ]
  },
  birthdate: {
    label: 'Fecha de nacimiento',
    type: 'date',
  },
  release: {
    label: 'Release',
    type: 'number'
  },
  roles: {
    label: 'Roles asignados',
    component: 'multiselect',
    options: [{
      label: 'User000',
      value: '1'
    }, {
      label: 'User001',
      value: '2'
    }, {
      label: 'Service000',
      value: '3'
    }, {
      label: 'Admin',
      value: '4'
    }]
  },
  tags: {
    label: 'Tags',
    component: 'multiselect',
    options: [{
      label: 'User000',
      value: '1'
    }, {
      label: 'User001',
      value: '2'
    }, {
      label: 'Service000',
      value: '3'
    }, {
      label: 'Admin',
      value: '4'
    }]
  },
}

const AdminProfileSettings = () => {

  return <BasicForm
    title={'Editar un usuario'}
    inputs={inputs}
    onSubmit={(data: Inputs) => console.log(data)}
    defaultValues={undefined}
  />;
};

export default AdminProfileSettings;