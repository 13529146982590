import BasicForm from "../../components/BasicForm";
import { FormInputContent } from "../../types";


type Inputs = {

}

const inputs: FormInputContent = {

}

const DietaryGuidelines = () => {

  return <BasicForm
    title={'Registro de pautas alimentarias'}
    inputs={inputs}
    onSubmit={(data: Inputs) => console.log(data)}
    defaultValues={undefined}
    />;
};

export default DietaryGuidelines;