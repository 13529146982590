
import { useSearchParams } from "react-router-dom";
import { authService } from "../../core/services";
import { getRedirectURi } from "../../utils";

const Login = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  if (code) {
    authService.login(code).then(() => {
      window.location.href = '/';
    });

    return;
  }

  console.log(import.meta.env)

  return (<div>
    <div className="mx-auto max-w-270">
      <div className="grid grid-cols-5 gap-8">
        <div className="col-span-5 xl:col-span-3">
          <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
            <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
              <h3 className="font-medium text-black dark:text-white">
                Weelock
              </h3>
            </div>
            <div className="p-7">
              <div className="flex justify-center gap-4.5">
                <a href={`https://${import.meta.env.VITE_USER_POOL_DOMAIN}/oauth2/authorize?client_id=${import.meta.env.VITE_USER_POOL_ID}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${getRedirectURi()}`}>
                  <button
                    className="flex justify-center rounded bg-primary py-2 px-6 font-medium text-gray hover:shadow-1"
                  >
                    Login
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>)
};

export default Login;