import AdminProfileSettings from "./views/AdminProfileSettings";
import DietaryGuidelines from "./views/DietaryGuidelines";
import FoodRegistry from "./service/views/FoodRegistry";
import NutritionalControls from "./views/NutritionalControls";
import NutritionalDataRegistration from "./views/NutritionalDataRegistration";
import PortionsRegistry from "./service/views/PortionsRegistry";
import ProfileSettings from "./views/ProfileSettings";
import SelectRole from "./views/SelectRole";


export const commonAuthRoutes = [
  {
    path: "/",
    element: <SelectRole />,
  },
  {
    path: "/",
    children: [{
      path: "/profile",
      element: <ProfileSettings />
    }, {
      path: "/admin/profile",
      element: <AdminProfileSettings />
    }, {
      path: "/nutritional/create",
      element: <NutritionalDataRegistration />
    }, {
      path: "/forms/2",
      element: <NutritionalControls />
    }, {
      path: '/forms/3',
      element: <FoodRegistry />
    }, {
      path: '/forms/4',
      element: <PortionsRegistry />
    }, {
      path: '/forms/5',
      element: <DietaryGuidelines />
    }]
  },
];

export const commonSideBar = [{
  path: '/admin/profile',
  name: 'Editar Usuario'
}, {
  path: '/nutritional/create',
  name: 'Registro datos alimentarios'
}, {
  path: '/forms/1',
  name: 'Registro de series de tiempo'
}, {
  path: '/forms/2',
  name: 'Registro de controles nutricionales'
}, {
  path: '/forms/3',
  name: 'Registro de alimentos'
}, {
  path: '/forms/4',
  name: 'Registro de porciones'
}, {
  path: '/forms/5',
  name: 'Registro de pautas alimentarias'
}]