import BasicForm from "../../components/BasicForm";
import { FormInputContent } from "../../types";

type Inputs = {
  registerId: string;
  responsibleId: string;
  generalDesc: string;
  weightVariationDesc: string;
  physicalActivityDesc: string;
  personalMotivation: string;
  personalObjetives: string;
  diseases: string;
  familiarDiseases: string;
  surgeries: string;
  referenceExams: string;
  generalObservations: string;
  dietaryHistory: string;
  foodAllergies: string;
  foodIntolerances: string;
  foodPreferences: string;
  foodAversions: string;
  foodDiagnosis: string;
  estimatedCalories: string;
  estimatedCarbohydrates: string;
  estimatedProteins: string;
  estimatedFats: string;
  firstMorningSnack: string;
  secondMorningSnack: string;
  lunch: string;
  firstAfternoonSnack: string;
  secondAfternoonSnack: string;
  dinner: string;
  firstEveningSnack: string;
  secondEveningSnack: string;
  anxietyLevel: string;
  appetiteVariationDuringAxiety: string;
  foodDuringAxiety: string;
  unplannedEating: string;
  abdominalPain: string;
  heartburn: string;
  nausea: string;
  vomiting: string;
  diarrhea: string;
  constipation: string;
  abdominalBloating: string;
  flatulence: string;
  gastrointestinalBleeding: string;
  stoolPatternChanges: string;
  comments: string;
}

const inputs: FormInputContent = {
  registerId: {
    label: 'Identificador del registrante',
  },
  responsibleId: {
    label: 'Identificador del responsable asignado',
  },
  generalDesc: {
    label: 'Descripción general del usuario',
    component: 'textarea'
  },
  weightVariationDesc: {
    label: 'Descripción de variaciones históricas de peso',
    component: 'textarea'
  },
  physicalActivityDesc: {
    label: 'Descripción de actividad física',
    component: 'textarea'
  },
  personalMotivation: {
    label: 'Motivación personal'
  },
  personalObjetives: {
    label: 'Objetivos personales'
  },
  diseases: {
    label: 'Enfermedades'
  },
  familiarDiseases: {
    label: 'Enfermedades familiares'
  },
  surgeries: {
    label: 'Cirugías'
  },
  referenceExams: {
    label: 'Exámenes de referencia'
  },
  generalObservations: {
    label: 'Observaciones generales'
  },
  dietaryHistory: {
    label: 'Antecedentes alimentarios'
  },
  foodAllergies: {
    label: 'Alergias alimentarias'
  },
  foodIntolerances: {
    label: 'Intolerancias alimentarias'
  },
  foodPreferences: {
    label: 'Preferencias alimentarias'
  },
  foodAversions: {
    label: 'Aversiones alimentarias'
  },
  foodDiagnosis: {
    label: 'Diagnóstico alimentario'
  },
  estimatedCalories: {
    label: 'Calorías estimadas',
    type: 'number'
  },
  estimatedCarbohydrates: {
    label: 'Carbohidratos estimados',
    type: 'number',
    step: '0.01'
  },
  estimatedProteins: {
    label: 'Proteinas estimadas',
    type: 'number',
    step: '0.01'
  },
  estimatedFats: {
    label: 'Grasas estimadas',
    type: 'number',
    step: '0.01'
  },
  firstMorningSnack: {
    label: 'Primera colación de mañana'
  },
  secondMorningSnack: {
    label: 'Segunda colación de mañana'
  },
  lunch: {
    label: 'Almuerzo'
  },
  firstAfternoonSnack: {
    label: 'Primera colación de tarde'
  },
  secondAfternoonSnack: {
    label: 'Segunda colación de tarde'
  },
  dinner: {
    label: 'Cena'
  },
  firstEveningSnack: {
    label: 'Primera colación de noche'
  },
  secondEveningSnack: {
    label: 'Segunda colación de noche'
  },
  anxietyLevel: {
    label: 'Nivel de ansiedad'
  },
  appetiteVariationDuringAxiety: {
    label: 'Variación del apetito ante ansiedad'
  },
  foodDuringAxiety: {
    label: 'Alimentos ante ansiedad'
  },
  unplannedEating: {
    label: 'Alimentación no programada'
  },
  abdominalPain: {
    label: 'Dolor abdominal',
    component: 'select',
    options: [{ label: 'Si', value: 'true' }, { label: 'No', value: 'false' }]
  },
  heartburn: {
    label: 'Acidez estomacal',
    component: 'select',
    options: [{ label: 'Si', value: 'true' }, { label: 'No', value: 'false' }]
  },
  nausea: {
    label: 'Nauseas',
    component: 'select',
    options: [{ label: 'Si', value: 'true' }, { label: 'No', value: 'false' }]
  },
  vomiting: {
    label: 'Vómitos',
    component: 'select',
    options: [{ label: 'Si', value: 'true' }, { label: 'No', value: 'false' }]
  },
  diarrhea: {
    label: 'Diarrea',
    component: 'select',
    options: [{ label: 'Si', value: 'true' }, { label: 'No', value: 'false' }]
  },
  constipation: {
    label: 'Estreñimiento',
    component: 'select',
    options: [{ label: 'Si', value: 'true' }, { label: 'No', value: 'false' }]
  },
  abdominalBloating: {
    label: 'Distención abdominal',
    component: 'select',
    options: [{ label: 'Si', value: 'true' }, { label: 'No', value: 'false' }]
  },
  flatulence: {
    label: 'Flatulencia',
    component: 'select',
    options: [{ label: 'Si', value: 'true' }, { label: 'No', value: 'false' }]
  },
  gastrointestinalBleeding: {
    label: 'Sangrado gastrointestinal',
    component: 'select',
    options: [{ label: 'Si', value: 'true' }, { label: 'No', value: 'false' }]
  },
  stoolPatternChanges: {
    label: 'Cambios de patrón de las heces',
    component: 'select',
    options: [{ label: 'Si', value: 'true' }, { label: 'No', value: 'false' }]
  },
  comments: {
    label: 'Comentarios'
  }
}


const NutritionalDataRegistration = () => {

  return <BasicForm
    title={'Registro de datos alimentarios'}
    inputs={inputs}
    onSubmit={(data: Inputs) => console.log(data)}
    defaultValues={undefined}
    />;
};

export default NutritionalDataRegistration;